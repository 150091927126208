import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
     width="332" height="9" viewBox="0 0 332 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61.4946 8.33102C54.6686 7.42759 4.62028 8.25375 0.972365 7.99818C-2.0123 7.64156 2.57526 1.47805 5.3112 0.568684C6.27845 0.24773 7.41146 0.18235 10.0092 0.354714C12.0819 0.485473 26.342 0.467639 40.1046 0.402259C53.8672 0.33688 67.1323 0.259614 67.1323 0.259614C67.8232 0.455753 69.9512 -0.180211 70.3381 0.0515895C70.9461 0.324995 92.723 0.372544 115.274 0.372544C137.825 0.372544 161.149 0.354711 164.797 0.360655C169.246 0.366598 165.681 0.717274 170.932 0.610289C182.097 0.384432 206.361 0.883691 214.818 0.616229C221.616 0.40226 223.053 0.414147 229.52 0.681609C236.097 0.955015 271.361 0.996621 275.451 0.622174C276.445 0.515189 277.192 0.562737 277.192 0.699439C277.661 0.71727 301.981 1.11549 302.064 1.21653C302.23 1.35324 302.976 1.4305 303.694 1.34135C305.021 1.21059 331.524 1.01445 331.855 1.41862C332.878 2.57762 328.263 7.38004 325.029 8.5331C321.63 9.75154 304.8 8.12894 290.871 8.73518C214.901 8.00412 230.017 8.06356 212.856 8.50338C211.336 8.07544 205.2 9.04425 201.553 8.29536C200.06 7.98629 189.144 7.86742 186.657 8.14677C183.921 8.45584 129.23 7.30277 122.818 8.17054C112.648 7.40976 71.1119 8.19432 61.4946 8.33102Z" fill="#FFBA00"/>
</svg>
  
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
    width="132" height="9" viewBox="0 0 132 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.4497 8.33102C21.7357 7.42759 1.83698 8.25375 0.386603 7.99818C-0.800069 7.64156 1.0239 1.47805 2.11168 0.568684C2.49625 0.24773 2.94673 0.18235 3.97957 0.354714C4.80365 0.485473 10.4733 0.467639 15.9452 0.402259C21.4171 0.33688 26.6912 0.259614 26.6912 0.259614C26.9659 0.455753 27.8119 -0.180211 27.9658 0.0515895C28.2075 0.324995 36.8658 0.372544 45.8317 0.372544C54.7977 0.372544 64.0713 0.354711 65.5217 0.360654C67.2907 0.366598 65.8733 0.717274 67.961 0.610289C72.4 0.384432 82.0472 0.883691 85.4095 0.616229C88.1125 0.40226 88.6838 0.414147 91.2549 0.681609C93.87 0.955015 107.89 0.996621 109.517 0.622174C109.912 0.515189 110.209 0.562737 110.209 0.699439C110.396 0.71727 120.065 1.11549 120.098 1.21653C120.164 1.35324 120.46 1.4305 120.746 1.34135C121.273 1.21059 131.811 1.01445 131.942 1.41862C132.349 2.57762 130.514 7.38004 129.228 8.5331C127.877 9.75154 121.185 8.12894 115.648 8.73518C85.4424 8.00412 91.4527 8.06356 84.6294 8.50338C84.025 8.07544 81.5857 9.04425 80.1354 8.29536C79.542 7.98629 75.2019 7.86742 74.213 8.14677C73.1252 8.45584 51.3805 7.30277 48.8314 8.17054C44.7879 7.40976 28.2734 8.19432 24.4497 8.33102Z" fill="#FFBA00"/>
      </svg>      
  </span>
)