import React from "react"

export const TitleUnderlineMarcadorPredictivoOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="374" height="10" viewBox="0 0 374 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.4853 9.23604C61.7616 8.28148 5.17973 9.12562 1.06823 8.83188C-2.28792 8.44533 3.09999 1.85732 6.21976 0.895093C7.31332 0.5491 8.58256 0.502563 11.4897 0.671399C16.0991 0.985904 75.7156 0.597134 75.7156 0.597134C76.502 0.803273 78.8658 0.124607 79.2987 0.374054C80.6622 0.957296 177.291 0.497194 185.532 0.493404C190.529 0.491305 186.575 0.872088 192.455 0.733109C204.987 0.457772 232.295 0.855765 241.79 0.525603C249.389 0.252583 251.028 0.245443 258.32 0.511839C265.773 0.753347 305.405 0.576449 309.937 0.127689C311.046 0.00428962 311.897 0.0516944 311.896 0.197935C311.896 0.229726 339.832 0.432735 339.96 0.547621C340.168 0.694571 341.019 0.7674 341.807 0.668342C343.269 0.520711 373.006 0.0685761 373.47 0.496163C374.928 1.72192 370.814 6.90906 367.356 8.16262C363.817 9.50492 344.417 7.91937 328.816 8.68023L256.189 8.51611L240.621 8.97187C238.839 8.52709 231.976 9.61012 227.834 8.80761C226.084 8.49011 213.715 8.40357 210.983 8.72492C208.604 8.97117 145.329 8.11397 138.836 8.97572C127.273 8.19886 80.3614 9.09496 69.4853 9.23604Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineMarcadorPredictivoTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="354" height="10" viewBox="0 0 354 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.6918 9.22315C58.3899 8.27002 4.89698 9.12466 1.01001 8.83168C-2.16285 8.44576 2.93213 1.85675 5.88176 0.893945C6.91568 0.547749 8.11564 0.500976 10.864 0.669273C15.2217 0.982922 71.5835 0.583094 71.5835 0.583094C72.327 0.789087 74.5618 0.109983 74.971 0.359349C76.26 0.942338 167.614 0.46431 175.405 0.458992C180.128 0.455966 176.391 0.837482 181.95 0.697412C193.797 0.419751 219.614 0.812678 228.591 0.480755C235.776 0.206325 237.325 0.198881 244.219 0.463924C251.265 0.70405 288.733 0.5198 293.017 0.0701986C294.066 -0.0534061 294.871 -0.00615939 294.87 0.140081C294.87 0.171872 321.28 0.369699 321.401 0.484561C321.598 0.631472 322.403 0.704143 323.147 0.604939C324.53 0.457037 352.643 -0.000613853 353.082 0.426887C354.46 1.65237 350.57 6.84027 347.3 8.09447C343.954 9.43743 325.613 7.85548 310.864 8.61923L242.202 8.46859L227.484 8.92723C225.8 8.48279 219.311 9.56709 215.395 8.76535C213.741 8.44817 202.047 8.36392 199.464 8.68578C197.216 8.93247 137.395 8.08701 131.257 8.94997C120.324 8.17525 75.9741 9.08006 65.6918 9.22315Z" fill="#FFBA00" />
    </svg>
  </span>
)