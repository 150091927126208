import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg style={{ bottom: "-2px" }} width="245" height="9" viewBox="0 0 245 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.5493 9C40.4841 8.16452 3.39497 9.09727 0.69911 8.84548C-1.5018 8.50786 2.01545 2.5622 4.0584 1.68666C4.77449 1.37192 5.60641 1.32616 7.51247 1.46922C10.5348 1.73818 49.6142 1.20598 49.6142 1.20598C50.1302 1.38909 51.6782 0.77107 51.9625 0.994246C52.8576 1.51499 116.2 0.805402 121.602 0.77679C124.877 0.759622 122.286 1.11441 126.141 0.971352C134.355 0.685228 152.257 0.959909 158.48 0.633728C163.461 0.364771 164.536 0.353335 169.317 0.570789C174.203 0.765353 200.182 0.484949 203.152 0.0672084C203.878 -0.0472411 204.436 -0.00718032 204.436 0.124437C204.436 0.153049 222.749 0.250328 222.834 0.353332C222.97 0.484949 223.529 0.547892 224.045 0.456332C225.003 0.318993 244.495 -0.178864 244.801 0.204542C245.759 1.30326 243.074 5.98424 240.809 7.12301C238.493 8.3419 225.772 6.97424 215.546 7.70672L167.937 7.78111L157.733 8.2389C156.564 7.84405 152.067 8.83976 149.35 8.13017C148.203 7.84977 140.094 7.80971 138.304 8.10728C136.745 8.33618 95.2647 7.75821 91.0103 8.55363C83.4282 7.88982 52.6786 8.83977 45.5493 9Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg style={{ bottom: "-2px" }} width="216" height="9" viewBox="0 0 216 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.1578 9C35.6921 8.16452 2.99311 9.09727 0.616358 8.84548C-1.32404 8.50786 1.77688 2.5622 3.57802 1.68666C4.20934 1.37192 4.9428 1.32616 6.62324 1.46922C9.2878 1.73818 43.7415 1.20598 43.7415 1.20598C44.1964 1.38909 45.5612 0.77107 45.8119 0.994246C46.601 1.51499 102.445 0.805402 107.208 0.77679C110.096 0.759622 107.812 1.11441 111.21 0.971352C118.451 0.685228 134.235 0.959909 139.722 0.633728C144.113 0.364771 145.06 0.353335 149.275 0.570789C153.583 0.765353 176.487 0.484949 179.105 0.0672084C179.746 -0.0472411 180.238 -0.00718033 180.238 0.124437C180.238 0.153049 196.383 0.250328 196.457 0.353332C196.578 0.484949 197.07 0.547892 197.525 0.456332C198.37 0.318993 215.555 -0.178864 215.824 0.204542C216.669 1.30326 214.302 5.98424 212.305 7.12301C210.263 8.3419 199.048 6.97424 190.033 7.70672L148.059 7.78111L139.062 8.2389C138.032 7.84405 134.067 8.83976 131.672 8.13017C130.66 7.84977 123.511 7.80971 121.933 8.10728C120.559 8.33618 83.9885 7.75821 80.2377 8.55363C73.553 7.88982 46.4432 8.83977 40.1578 9Z" fill="#FFA700"/>
</svg>
  </span>
)
