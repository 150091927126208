import React from "react"
import TemplatePageMarcadorPredictivo from "@components/pageFunctionalities/pageMarcadorPredictivo/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Descubre nuestro Marcador Predictivo con Machine Learning [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/marcador-predictivo/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Utiliza nuestro marcador predictivo para aumentar la productividad de tus agentes y mejorar el contacto con todos tus clientes potenciales."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Descubre nuestro Marcador Predictivo con Machine Learning"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/marcador-predictivo/`}
      />
       <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Utiliza nuestro marcador predictivo para aumentar la productividad de tus agentes y mejorar el contacto con todos tus clientes potenciales."
      />
    </Helmet>
    <TemplatePageMarcadorPredictivo location={location} />
  </div>
)

export default IndexPage
